import { mapGetters } from 'vuex';
import UserService from '../services/UserService';

export default {
  mixins: [UserService],
  data() {
    return {
      language: {
        value: 'pt-br',
        label: 'Português',
        flag: 'brazil.png'
      },
      languages: [
        {
          value: 'pt-br',
          label: 'Português',
          flag: 'brazil.png'
        },
        {
          value: 'en',
          label: 'English',
          flag: 'united-states.png'
        }
      ]
    };
  },

  watch: {
    language() {
      this.$i18n.locale = this.language.value;
    }
  },

  methods: {
    logOut() {
      this.showDialog('Atenção', 'Deseja sair da plataforma?', async () => {
        await this.$http.get('/logout');
        this.ActionSetToken(null);
        this.ActionSetUser(null);
        localStorage.removeItem('afilio_store');
        this.$router.push({ name: 'Auth' });
      });
    },

    async forgetPassword(form) {
      this.onLoading(true);
      try {
        if (!form.email) throw this.$t('validator.required.email');
        const { status } = await this.$http.post('/create/user/reset', this.parseQs(form));
        if (status === 200)
          this.showInfoDialog(
            'Se o e-mail informado estiver correto, você receberá instruções na sua caixa de entrada. <br>Verifique sua caixa de Spam ou entre em contato com o suporte, se necessário.'
          );
      } catch (error) {
        if (typeof error.response === 'undefined') this.showInfoDialog('Erro ao enviar e-mail.');
        else if (error.response.status == 429) {
          this.errorNotify('reset_pass_rate_limit');
        } else if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async collectUserData() {
      this.onLoading(true);
      try {
        const { data } = await this.userProfile();
        return data.auth.user;
      } catch (error) {
        if (typeof error.response === 'undefined') this.showDialog('Ocorreu um erro interno.');
        else if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    /**
     * createAccount
     * @param {*} form
     */
    async createAccount(form) {
      if (!this.checkTerms) {
        this.showInfoDialog('Atenção', 'você precisa aceitar os termos de uso para continuar.');
        return;
      }
      this.onLoading(true);
      try {
        if (!form.first_name) throw 'Nome não informado';
        if (!form.last_name) throw 'Sobrenome não informado';

        if (!form.email) throw 'Email não informado';
        if (!form.password) throw 'Senha não informada';

        /**
         * Tratamento de nome para criar conta
         * @type {Object}
         */
        let createAccountForm = {};

        createAccountForm = {
          name: `${form.first_name} ${form.last_name}`,
          email: form.email,
          password: form.password
        };

        const { data } = await this.$http.post('/v2/register/affiliate', createAccountForm);
        if (data)
          this.showInfoDialog(
            'Cadastro realizado',
            `${createAccountForm.name} sua conta foi criada com sucesso. Foi enviado um email de confirmação para o email cadastrado`,
            this.redirectToLogin
          );
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error.response.data.affiliate_register.email[0]);
      } finally {
        this.onLoading(false);
      }
    },

    redirectToLogin() {
      this.$router.push({ name: 'Auth' });
    },

    /**
     * setModeDark
     * @param {*} form
     */
    setModeDark() {
      if (this.$q.dark.isActive) this.$q.dark.set(false);
      else this.$q.dark.set(true);
    },

    async forceLogout() {
      await this.$http.get('/logout');
      this.ActionSetToken(null);
      this.ActionSetUser(null);
      localStorage.removeItem('afilio_store');
      this.redirectToLogin();
    }
  },

  computed: {
    ...mapGetters('Account', ['user'])
  }
};
